import { schema } from 'normalizr'

// Circular Dependencies - Use the define method
// const item = new schema.Entity('item')
// const child = new schema.Entity('child')
// item.define({ children: [child] })

// Define Entities
// START ENTITY DEFINITIONS
const activityLog = new schema.Entity('activityLogs')
const adAccount = new schema.Entity('adAccounts')
const adAccountGroup = new schema.Entity('adAccountGroups')
const adAccountLink = new schema.Entity('adAccountLinks')
const adCampaign = new schema.Entity('adCampaigns')
const adCampaignAd = new schema.Entity('adCampaignAds')
const adCampaignStatus = new schema.Entity('adCampaignStatuses')
const adQueue = new schema.Entity('adQueues')
const adQueueStatus = new schema.Entity('adQueueStatuses')
const adTemplate = new schema.Entity('adTemplates')
const adTemplatePayload = new schema.Entity('adTemplatePayloads')
const adTemplateProduct = new schema.Entity('adTemplateProducts')
const artwork = new schema.Entity('artworks')
const artworkTemplate = new schema.Entity('artworkTemplates')
const artworkTemplateGroup = new schema.Entity('artworkTemplateGroups')
const artworkTemplateGroupLink = new schema.Entity('artworkTemplateGroupLinks')
const asset = new schema.Entity('assets')
const assetType = new schema.Entity('assetTypes')
const autoOrderQueue = new schema.Entity('autoOrderQueues')
const autoOrderQueueStatus = new schema.Entity('autoOrderQueueStatuses')
const brand = new schema.Entity('brands')
const campaign = new schema.Entity('campaigns')
const campaignObjective = new schema.Entity('campaignObjectives')
const campaignStatus = new schema.Entity('campaignStatuses')
const campaignType = new schema.Entity('campaignTypes')
const campaignUser = new schema.Entity('campaignUsers')
const campaignValidation = new schema.Entity('campaignValidations')
const caption = new schema.Entity('captions')
const client = new schema.Entity('clients')
const clientPriceList = new schema.Entity('clientPriceLists')
const clientUser = new schema.Entity('clientUsers')
const comment = new schema.Entity('comments')
const conversation = new schema.Entity('conversations')
const cutterProfile = new schema.Entity('cutterProfiles')
const cutterProfileModule = new schema.Entity('cutterProfileModules')
const dataStoreItem = new schema.Entity('dataStoreItems')
const externalPlatform = new schema.Entity('externalPlatforms')
const externalPlatformEntity = new schema.Entity('externalPlatformEntities')
const facebookCampaign = new schema.Entity('facebookCampaigns')
const googleCampaign = new schema.Entity('googleCampaigns')
const image = new schema.Entity('images')
const landingPage = new schema.Entity('landingPages')
const order = new schema.Entity('orders')
const orderItem = new schema.Entity('orderItems')
const orderItemOptionValue = new schema.Entity('orderItemOptionValues')
const packageProduct = new schema.Entity('packageProducts')
const pendingGoogleCampaign = new schema.Entity('pendingGoogleCampaigns')
const priceList = new schema.Entity('priceLists')
const priceListItem = new schema.Entity('priceListItems')
const priceListItemOption = new schema.Entity('priceListItemOptions')
const priceListItemOptionValue = new schema.Entity('priceListItemOptionValues')
const product = new schema.Entity('products')
const productLine = new schema.Entity('productLines')
const productOption = new schema.Entity('productOptions')
const productOptionKeyType = new schema.Entity('productOptionKeyTypes')
const productOptionProduct = new schema.Entity('productOptionProducts')
const productOptionValue = new schema.Entity('productOptionValues')
const productOptionValuesSubset = new schema.Entity('productOptionValuesSubsets')
const productPackage = new schema.Entity('productPackages')
const project = new schema.Entity('projects')
const realhubArtworkTemplate = new schema.Entity('realhubArtworkTemplates')
const user = new schema.Entity('users')
const userType = new schema.Entity('userTypes')
// END ENTITY DEFINITIONS

activityLog.define({
  user,
})

adAccount.define({
  adAccountLinks: [adAccountLink],
})

adAccountLink.define({
  adAccount,
})

adCampaign.define({
  adQueue,
  campaign,
  facebookCampaign,
  googleCampaign,
})

adQueue.define({
  adTemplatePayload,
  campaign,
  facebookCampaign,
  pendingGoogleCampaign,
})

adTemplatePayload.define({
  adTemplate,
})

adTemplateProduct.define({
  adTemplate,
  product,
})

campaign.define({
  adQueues: [adQueue],
  adTemplate,
  artworks: [artwork],
  assetGroup: {
    assets: [asset],
  },
  campaignType,
  captions: [caption],
  landingPage,
  orderItem,
  project,
})

campaignUser.define({
  user,
})

client.define({
  brand,
})

clientPriceList.define({
  client,
  priceList,
})

clientUser.define({
  client,
  user,
})

comment.define({
  user,
})

conversation.define({
  comments: [comment],
})

externalPlatformEntity.define({
  externalPlatform,
})

order.define({
  externalPlatformEntities: [externalPlatformEntity],
  project,
})

orderItem.define({
  order,
  orderItemOptionValues: [orderItemOptionValue],
  priceListItem,
})

pendingGoogleCampaign.define({
  googleCampaignEntity: googleCampaign,
})

packageProduct.define({
  package: productPackage,
})

priceList.define({
  clientPriceLists: [clientPriceList],
  priceListItems: [priceListItem],
})

priceListItem.define({
  priceListItemOptions: [priceListItemOption],
  priceList,
  product,
})

priceListItemOption.define({
  priceListItemOptionValues: [priceListItemOptionValue],
  productOption,
})

priceListItemOptionValue.define({
  productOptionValue,
})

product.define({
  campaignType,
  productOptionProducts: [productOptionProduct],
  productOptionValuesSubsets: [productOptionValuesSubset],
})

productOptionProduct.define({
  product,
  productOption,
})

productOptionValuesSubset.define({
  productOptionValue,
})

productPackage.define({
  packageProducts: [packageProduct],
  products: [product],
})

productOption.define({
  productOptionValues: [productOptionValue],
  productOptionValuesSubsets: [productOptionValuesSubset],
})

project.define({
  dataStoreItems: [dataStoreItem],
  externalPlatformEntities: [externalPlatformEntity],
})

user.define({
  assetGroup: {
    assets: [asset],
  },
  clientUsers: [clientUser],
})

// Export Schemas
export const Schemas = {
// START SCHEMA DEFINITION
  ACTIVITY_LOG: activityLog,
  ACTIVITY_LOG_ARRAY: [activityLog],
  AD_ACCOUNT: adAccount,
  AD_ACCOUNT_ARRAY: [adAccount],
  AD_ACCOUNT_GROUP: adAccountGroup,
  AD_ACCOUNT_GROUP_ARRAY: [adAccountGroup],
  AD_ACCOUNT_LINK: adAccountLink,
  AD_ACCOUNT_LINK_ARRAY: [adAccountLink],
  AD_CAMPAIGN: adCampaign,
  AD_CAMPAIGN_AD_: adCampaignAd,
  AD_CAMPAIGN_AD_ARRAY: [adCampaignAd],
  AD_CAMPAIGN_ARRAY: [adCampaign],
  AD_CAMPAIGN_STATUS: adCampaignStatus,
  AD_CAMPAIGN_STATUS_ARRAY: [adCampaignStatus],
  AD_QUEUE: adQueue,
  AD_QUEUE_ARRAY: [adQueue],
  AD_QUEUE_STATUS: adQueueStatus,
  AD_QUEUE_STATUS_ARRAY: [adQueueStatus],
  AD_TEMPLATE: adTemplate,
  AD_TEMPLATE_ARRAY: [adTemplate],
  AD_TEMPLATE_PRODUCT: adTemplateProduct,
  AD_TEMPLATE_PRODUCT_ARRAY: [adTemplateProduct],
  ARTWORK: artwork,
  ARTWORK_ARRAY: [artwork],
  ARTWORK_TEMPLATE: artworkTemplate,
  ARTWORK_TEMPLATE_ARRAY: [artworkTemplate],
  ARTWORK_TEMPLATE_GROUP: artworkTemplateGroup,
  ARTWORK_TEMPLATE_GROUP_ARRAY: [artworkTemplateGroup],
  ARTWORK_TEMPLATE_GROUP_LINK: artworkTemplateGroupLink,
  ARTWORK_TEMPLATE_GROUP_LINK_ARRAY: [artworkTemplateGroupLink],
  ASSET: asset,
  ASSET_ARRAY: [asset],
  ASSET_TYPE: assetType,
  ASSET_TYPE_ARRAY: [assetType],
  AUTO_ORDER_QUEUE: autoOrderQueue,
  AUTO_ORDER_QUEUE_ARRAY: [autoOrderQueue],
  AUTO_ORDER_QUEUE_STATUS: autoOrderQueueStatus,
  AUTO_ORDER_QUEUE_STATUS_ARRAY: [autoOrderQueueStatus],
  BRAND: brand,
  BRAND_ARRAY: [brand],
  CAMPAIGN: campaign,
  CAMPAIGN_ARRAY: [campaign],
  CAMPAIGN_OBJECTIVE: campaignObjective,
  CAMPAIGN_OBJECTIVE_ARRAY: [campaignObjective],
  CAMPAIGN_STATUS: campaignStatus,
  CAMPAIGN_STATUS_ARRAY: [campaignStatus],
  CAMPAIGN_TYPE: campaignType,
  CAMPAIGN_TYPE_ARRAY: [campaignType],
  CAMPAIGN_USER: campaignUser,
  CAMPAIGN_USER_ARRAY: [campaignUser],
  CAMPAIGN_VALIDATION: campaignValidation,
  CAMPAIGN_VALIDATION_ARRAY: [campaignValidation],
  CAPTION: caption,
  CAPTION_ARRAY: [caption],
  CLIENT: client,
  CLIENT_ARRAY: [client],
  CLIENT_PRICE_LIST: clientPriceList,
  CLIENT_PRICE_LIST_ARRAY: [clientPriceList],
  CLIENT_USER: clientUser,
  CLIENT_USER_ARRAY: [clientUser],
  COMMENT: comment,
  COMMENT_ARRAY: [comment],
  CONVERSATION: conversation,
  CONVERSATION_ARRAY: [conversation],
  CUTTER_PROFILE: cutterProfile,
  CUTTER_PROFILE_ARRAY: [cutterProfile],
  CUTTER_PROFILE_MODULE: cutterProfileModule,
  CUTTER_PROFILE_MODULE_ARRAY: [cutterProfileModule],
  DATA_STORE_ITEM: dataStoreItem,
  DATA_STORE_ITEM_ARRAY: [dataStoreItem],
  EXTERNAL_PLATFORM: externalPlatform,
  EXTERNAL_PLATFORM_ARRAY: [externalPlatform],
  EXTERNAL_PLATFORM_ENTITY: externalPlatformEntity,
  EXTERNAL_PLATFORM_ENTITY_ARRAY: [externalPlatformEntity],
  IMAGE: image,
  IMAGE_ARRAY: [image],
  LANDING_PAGE: landingPage,
  LANDING_PAGE_ARRAY: [landingPage],
  ORDER: order,
  ORDER_ARRAY: [order],
  ORDER_ITEM: orderItem,
  ORDER_ITEM_ARRAY: [orderItem],
  ORDER_ITEM_OPTION_VALUE: orderItemOptionValue,
  ORDER_ITEM_OPTION_VALUE_ARRAY: [orderItemOptionValue],
  PACKAGE_PRODUCT: packageProduct,
  PACKAGE_PRODUCT_ARRAY: [packageProduct],
  PRICE_LIST: priceList,
  PRICE_LIST_ARRAY: [priceList],
  PRICE_LIST_ITEM: priceListItem,
  PRICE_LIST_ITEM_ARRAY: [priceListItem],
  PRICE_LIST_ITEM_OPTION: priceListItemOption,
  PRICE_LIST_ITEM_OPTION_ARRAY: [priceListItemOption],
  PRICE_LIST_ITEM_OPTION_VALUE: priceListItemOptionValue,
  PRICE_LIST_ITEM_OPTION_VALUE_ARRAY: [priceListItemOptionValue],
  PRODUCT: product,
  PRODUCT_ARRAY: [product],
  PRODUCT_LINE: productLine,
  PRODUCT_LINE_ARRAY: [productLine],
  PRODUCT_OPTION: productOption,
  PRODUCT_OPTION_ARRAY: [productOption],
  PRODUCT_OPTION_KEY_TYPE: productOptionKeyType,
  PRODUCT_OPTION_KEY_TYPE_ARRAY: [productOptionKeyType],
  PRODUCT_OPTION_PRODUCT: productOptionProduct,
  PRODUCT_OPTION_PRODUCT_ARRAY: [productOptionProduct],
  PRODUCT_OPTION_VALUE: productOptionValue,
  PRODUCT_OPTION_VALUES_SUBSET: productOptionValuesSubset,
  PRODUCT_OPTION_VALUES_SUBSET_ARRAY: [productOptionValuesSubset],
  PRODUCT_OPTION_VALUE_ARRAY: [productOptionValue],
  PRODUCT_PACKAGE: productPackage,
  PRODUCT_PACKAGE_ARRAY: [productPackage],
  PROJECT: project,
  PROJECT_ARRAY: [project],
  REALHUB_ARTWORK_TEMPLATE: realhubArtworkTemplate,
  REALHUB_ARTWORK_TEMPLATE_ARRAY: [realhubArtworkTemplate],
  USER: user,
  USER_ARRAY: [user],
  USER_TYPE: userType,
  USER_TYPE_ARRAY: [userType],
// END SCHEMA DEFINITION
}

export default Schemas
