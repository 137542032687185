import { Route, Routes } from 'react-router-dom'

// import AdAccountGroupEdit from './Edit'
import AdAccountGroupsList from './List'

const AdAccountGroupsRoutes = () => (
  <Routes>
    <Route path="/" element={<AdAccountGroupsList />} />
    {/*  <Route path="/:adAccountGroupId/edit/*" element={<AdAccountGroupEdit />} />  */}
  </Routes>
)

export default AdAccountGroupsRoutes
