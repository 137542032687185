import { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'

import { faCheckCircle } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'
import type { DefaultContext } from '@contexts/pageContext'

import useAdAccountGroups from '@hooks/useAdAccountGroups'
import { generateUrls } from '@hooks/useAdAccountGroup'

import ListBlankState from '@components/ListBlankState'
import PageFilterModule from '@components/PageFilterModule'
import PageHeader from '@components/PageHeader'

import AdAccountGroupListItem from '../AdAccountGroupListItem'

import type { PageContextPayload } from '../../index'

const buildPageFilterFields = () => {
  const style = { marginBottom: 16 }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        placeholder: 'Search by Ad Account Group name or ID',
        style,
      },
      key: 'string',
      label: 'Name or ID',
    },
  ]
}

interface PageContentProps {
  pageFilters: {},
}

const PageContent = (props: PageContentProps) => {
  const { pageFilters } = props

  // useLocation to refresh the window.location. If not used location won't reload if URL changes
  useLocation()

  const adAccountGroupsPayload = useAdAccountGroups({
    filters: {
      ...pageFilters,
    },
    performHttpRequests: true,
  })

  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredAdAccountGroups,
    filteredAdAccountGroupsCount,
    hasFilteredAdAccountGroups,
    loading,
  } = adAccountGroupsPayload

  const pageContext = useContext<DefaultContext<PageContextPayload>>(PageContext)
  const {
    callbacks: {
      resetFilters,
      showCreateOrEditAdAccountGroupModal,
      updateFilters,
    },
    defaultFilters,
  } = pageContext

  const { adAccountGroupsIndexUrl } = generateUrls()

  return (
    <>
      <Helmet>
        <title>Ad Account Groups | AIM Digital</title>
      </Helmet>

      <PageHeader
        actionContent={(
          <Button buttonStyle="primaryCreate" onClick={() => showCreateOrEditAdAccountGroupModal()} size="medium">
            Create
          </Button>
        )}
        activeTabBarItemKey="active"
        tabBarItems={[
          {
            href: adAccountGroupsIndexUrl,
            icon: faCheckCircle,
            key: 'active',
            title: 'Active',
          }
        ]}
        title="Ad Account Groups"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasFilteredAdAccountGroups && <ListBlankState />}

            {!loading && hasFilteredAdAccountGroups && (
              <DashboardModule contentBoxProps={{ flexDirection: 'column' }} title="Ad Account Groups">
                <Box flexDirection="column">
                  {filteredAdAccountGroups.map(adAccountGroup => (
                    <AdAccountGroupListItem
                      key={adAccountGroup.id}
                      adAccountGroup={adAccountGroup}
                    />
                  ))}
                </Box>

                <DashboardModule.LoadMoreFooter
                  callbacks={{ loadMore }}
                  canLoadMore={canLoadMore}
                  entityCount={filteredAdAccountGroupsCount}
                  loading={loading}
                />
              </DashboardModule>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              defaultFilters={defaultFilters}
              filterFields={buildPageFilterFields()}
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default PageContent
