import { useMemo } from 'react'

import { matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['adAccountGroups']

type AdAccountGroupFilters = {
  string?: string,
}

type UseAdAccountGroupsOptions = {
  filters?: AdAccountGroupFilters,
  performHttpRequests?: boolean,
}

function useAdAccountGroups(options: UseAdAccountGroupsOptions) {
  const { filters = {} } = options
  const {
    string: filterString,
  } = filters

  const {
    updatedEntities: { adAccountGroups: adAccountGroupsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { adAccountGroups } = useSelector(reduxState => reduxState.entities)

  const filteredAdAccountGroups = useMemo(() => {
    const filtered = Object.values(adAccountGroups).filter((adAccountGroup) => {
      const { id, name } = adAccountGroup

      const matchId = id.toString() === filterString
      const matchTitle = matchFilterString(name, filterString)
      const matchString = matchId || matchTitle

      return matchString
    })

    return sortArrayBy(filtered, 'asc', 'name')
  }, [adAccountGroupsUpdatedAt, JSON.stringify(filters)])

  const filteredAdAccountGroupsCount = filteredAdAccountGroups.length
  const hasFilteredAdAccountGroups = !!filteredAdAccountGroupsCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredAdAccountGroupsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingAdAccountGroups } = useReduxAction(
    'adAccountGroups',
    'loadAdAccountGroups',
    {
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredAdAccountGroups,
    filteredAdAccountGroupsCount,
    hasFilteredAdAccountGroups,
    loading: loadingAdAccountGroups,
  }
}

export default useAdAccountGroups
