import { DashboardModuleListItem } from '@campaignhub/suit-theme'

import type { AdAccountGroupModel } from '@models/types'

import { generateUrls } from '@hooks/useAdAccountGroup'

interface AdAccountGroupListItemProps {
  adAccountGroup: AdAccountGroupModel,
}

const AdAccountGroupListItem = (props: AdAccountGroupListItemProps) => {
  const { adAccountGroup, adAccountGroup: { id, name } } = props

  const { editAdAccountGroupUrl } = generateUrls(adAccountGroup)

  return (
    <DashboardModuleListItem
      href={editAdAccountGroupUrl}
      title={name}
    >
      <DashboardModuleListItem.TertiaryText>
        {`# ${id}`}
      </DashboardModuleListItem.TertiaryText>
    </DashboardModuleListItem>
  )
}

export default AdAccountGroupListItem
