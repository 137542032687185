import { Route, Routes, HashRouter as Router } from 'react-router-dom'

import AdAccountGroups from './packs/AdAccountGroups'
import AdDeployer from './packs/AdDeployer'
import AdLoader from './packs/AdLoader'
import Admin from '.'
import ArtworkTemplateGroups from './packs/ArtworkTemplateGroups'
import AutoOrderQueues from './packs/AutoOrderQueues'
import Brands from './packs/Brands'
import Clients from './packs/Clients'
import CutterProfiles from './packs/CutterProfiles'
import OrderMonitoring from './packs/OrderMonitoring'
import Orders from './packs/Orders'
import PriceLists from './packs/PriceLists'
import ProductOptions from './packs/ProductOptions'
import Products from './packs/Products'
import Users from './packs/Users'
import Validator from './packs/Validator'

const AdminRoutes = () => (
  <Router>
    <Admin>
      <Routes>
        <Route path="/" element={<OrderMonitoring />} />
        <Route path="/adAccountGroups/*" element={<AdAccountGroups />} />
        <Route path="/adDeployer" element={<AdDeployer />} />
        <Route path="/adLoader" element={<AdLoader />} />
        <Route path="/artworkTemplateGroups/*" element={<ArtworkTemplateGroups />} />
        <Route path="/autoOrderQueues/*" element={<AutoOrderQueues />} />
        <Route path="/brands/*" element={<Brands />} />
        <Route path="/cutterProfiles/*" element={<CutterProfiles />} />
        <Route path="/orders/*" element={<Orders />} />
        <Route path="/organisations/*" element={<Clients />} />
        <Route path="/priceLists/*" element={<PriceLists />} />
        <Route path="/productOptions/*" element={<ProductOptions />} />
        <Route path="/products/*" element={<Products />} />
        <Route path="/users/*" element={<Users />} />
        <Route path="/validator" element={<Validator />} />
      </Routes>
    </Admin>
  </Router>
)

export default AdminRoutes
