import type { AdAccountGroupModel } from '@models/types'

export const generateUrls = (adAccountGroup?: Partial<AdAccountGroupModel>) => {
  const { id } = adAccountGroup || {}

  return {
    adAccountGroupsIndexUrl: '#/adAccountGroups',
    editAdAccountGroupRelationsUrl: `#/adAccountGroup/${id}/edit/relations`,
    editAdAccountGroupUrl: `#/adAccountGroup/${id}/edit/`,
  }
}